import * as jquery from "jquery";

export class Filter {
    constructor(protected filterElement: JQuery, protected config: {} = {}) {}

    public observe(): void {
        // Initialize the materialize modal.
        this.filterElement.modal();
        // Init select boxes.
        const selectFields = this.filterElement.find("select");
        if (selectFields.length > 0) {
            selectFields.formSelect({
                dropdownOptions: {
                    onOpenStart() {
                        const ele = jquery(this);
                        // This is a tricky fix since onOpenEnd doesn"t work.
                        window.setTimeout(() => {
                            const dropdownEle = jquery(ele[0].dropdownEl);
                            if (dropdownEle) {
                                dropdownEle.css({"top": 0, "margin-bottom": "20px"});
                            }
                        }, 50);
                    },
                },
            });
        }

        // Handle form submit.
        const form = this.filterElement.find("form.tg-filter-form");
        if (form.length > 0) {
            this.filterElement.find(".filter-search-btn").on("click", (event) => {
                // window.location.replace = form.serialize();
                const uri = this.filterElement.attr("tg-uri");
                if (uri) {
                    window.location.href = uri + "?" + form.serialize();
                }
            });
        }
    }

    public openFilter(): void {
        this.filterElement.modal("open");
    }
}

export default function createFilter(filterElement: JQuery, config: {} = {}): Filter {
    return new Filter(filterElement, config);
}
