import * as jquery from "jquery";

class HotKey {
    protected keys: {} = {};
    protected delimiter: string = "_";
    protected registry: number[] = [];
    protected keyMap: {} = {
        a: 65,
        b: 66,
        c: 67,
        d: 68,
        e: 69,
        f: 70,
        g: 71,
        h: 72,
        i: 73,
        j: 74,
        k: 75,
        l: 76,
        m: 77,
        n: 78,
        o: 79,
        p: 80,
        q: 81,
        r: 82,
        s: 83,
        t: 84,
        u: 85,
        v: 86,
        w: 87,
        x: 88,
        y: 89,
        z: 90,
    };

    constructor(protected config: {} = {}) {}

    public add(keys, action): HotKey {
        const numberKeys = [];
        for (const key of keys) {
            numberKeys.push(this.keyMap[key]);
        }
        this.keys[numberKeys.sort().join(this.delimiter)] = action;
        return this;
    }

    public enable(): HotKey {
        const docEle = jquery(document);
        docEle.keydown((event) => {
            if ($(event.target).is('input, textarea')) {
                return;
            }

            if (this.registry.indexOf(event.which) < 0) {
                this.registry.push(event.which);
                if (this.match()) {
                    this.registry = [];
                }
            }
        });
        docEle.keyup((event) => {
            const index = this.registry.indexOf(event.which);
            if (index > -1) {
                this.registry.splice(index, 1);
            }
        });
        return this;
    }

    protected match(): boolean {
        const currentKeys = this.registry.sort().join(this.delimiter);
        if (this.keys.hasOwnProperty(currentKeys)) {
            this.keys[currentKeys]();
            return true;
        }
        return false;
    }
}

export default function createHotKey(config: {} = {}): HotKey {
    return new HotKey(config);
}
