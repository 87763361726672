import * as jquery from "jquery";

declare interface IImage {
    id: string;
    url: string;
}

declare interface IBase64Image {
    content: string;
}

declare interface IConfig {
    max?: number;
    images?: IImage[];
}

declare interface IImagePreviewElements {
    identity: string;
    container: JQuery;
    preview: JQuery;
    previewImage: JQuery;
    cancelButton: JQuery;
    contentInput: JQuery;
}

declare interface IImageAddElements {
    container: JQuery,
    button: JQuery;
    fileInput: JQuery;
}

export class ImageUploader {
    protected _previewHtml: string;
    protected _itemListContainer: JQuery;
    protected _itemList: JQuery;
    protected _imageNewElements: IImageAddElements;
    protected _imagePreviews: {
        [key: number]: ImagePreview;
    } = {};
    protected _imageIdentities: string[] = [];
    protected _counter: number = 1;

    constructor(protected _component: JQuery, protected _config: IConfig = {max: 20, images: []}) {
        const previewOrigin = _component.find('.preview-origin');
        previewOrigin.remove();
        this._previewHtml = previewOrigin.html();
        this._itemListContainer = _component.find('.item-list-container');
        this._itemList = _component.find('.item-list');
        const imageNewElementsContainer = this._itemList.find('.item.new');
        this._imageNewElements = {
            container: imageNewElementsContainer,
            button: imageNewElementsContainer.find('.new-button'),
            fileInput: imageNewElementsContainer.find('input.file'),
        };
    }

    observe() {
        const imageNewElements = this._imageNewElements;
        imageNewElements.button.on('click', function() {
            imageNewElements.fileInput.trigger('click');
        });

        // Click to upload.
        imageNewElements.fileInput.on('change', () => {
            const fileInputHtmlEle = this._imageNewElements.fileInput[0];
            if (fileInputHtmlEle) {
                this.loadFiles((<HTMLInputElement>fileInputHtmlEle).files);
            }
        });

        // Drag and drop to upload.
        this._itemListContainer.on('drop dragdrop', (event) => {
            event.preventDefault();
            jquery(event.currentTarget).removeClass('drag-over');
            const files = (<DragEvent>event.originalEvent).dataTransfer.files;
            if (files) {
                this.loadFiles(files);
            }
        });
        this._itemListContainer.on('dragenter', (event) => {
            event.preventDefault();
        });
        this._itemListContainer.on('dragover', (event) => {
            event.preventDefault();
            jquery(event.currentTarget).addClass('drag-over');
        });
        this._itemListContainer.on('dragleave', (event) => {
            jquery(event.currentTarget).removeClass('drag-over');
        });

        // Add existed images (via their urls).
        this.addExistedImages();
    }

    reset() {
        // Remove all the previews.
        for (const index in this._imagePreviews) {
            if (this._imagePreviews.hasOwnProperty(index)) {
                this._imagePreviews[index].elements.container.remove();
            }
        }
        this._imagePreviews = {};
        this._imageIdentities = [];
        this._counter = 1;
        // Add all the existed image previews.
        this.addExistedImages();
        this.resetImageNew();
    }

    loadFiles(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();
            reader.onload = (e) => {
                // Create a new preview window for each uploaded file.
                this.addBase64ImagePreview({content: <string>(<FileReader>e.target).result});
            };
            reader.readAsDataURL(file);
        }
    }

    resetImageNew(): void {
        if (this._imageIdentities.length >= this._config.max) {
            this._imageNewElements.container.hide(350);
        } else {
            this._imageNewElements.container.show(350);
        }
    }

    addExistedImages(): void {
        // Create previews for existed images.
        if (this._config.images && this._config.images.length > 0) {
            for (let image of this._config.images) {
                this.addImagePreview({id: image.id, url: image.url});
            }
        }
    }

    addImagePreview(image: IImage): void {
        this.createImagePreview(
            (imagePreview) => {
                imagePreview.loadImage(image);
            }
        );
    }

    addBase64ImagePreview(image: IBase64Image): void {
        this.createImagePreview(
            (imagePreview) => {
                imagePreview.loadBase64Image(image);
            }
        );
    }

    protected createImagePreview(setImagePreview: (imagePreview: ImagePreview) => void): ImagePreview|void {
        if (this._imageIdentities.length >= this._config.max) {
            return null;
        }
        // Create the preview window and add it to the page.
        const item = jquery(this._previewHtml);
        const identity = `item_${this._counter}`;
        item.attr('identity', `item_${this._counter}`);
        const imagePreviewElements = {
            identity: identity,
            container: item,
            preview: item.find('.preview'),
            previewImage: item.find('.preview img'),
            cancelButton: item.find('.cancel-button'),
            contentInput: item.find('input.content'),
        };
        const imagePreview = new ImagePreview(imagePreviewElements);
        setImagePreview(imagePreview);
        this._imagePreviews[identity] = imagePreview;
        this._imageIdentities.push(identity);
        this._counter++;
        this.resetImageNew();
        item.insertBefore(this._imageNewElements.container);
        this.observeImagePreview(imagePreview);
        return imagePreview;
    }

    protected observeImagePreview(imagePreview: ImagePreview): void {
        // Observe the preview window.
        imagePreview.observe((imagePreview) => {
            // Remove from the list (memory).
            delete this._imagePreviews[imagePreview.elements.container.attr('identity')];
            // Remove the identity.
            this._imageIdentities.splice(this._imageIdentities.indexOf(imagePreview.elements.identity), 1);
            // Reset the add new window.
            this.resetImageNew();
        });
    }
}

export class ImagePreview {
    constructor(protected _elements: IImagePreviewElements) {}

    get elements(): IImagePreviewElements {
        return this._elements;
    }

    loadImage(image: IImage): void {
        const elements = this.elements;
        elements.container.hide();
        elements.previewImage.attr('src', image.url);
        elements.contentInput.val(image.id);
        // console.log(elements.contentInput);
        // console.log(elements.contentInput.val());
        elements.container.show(350);
    }

    loadBase64Image(image: IBase64Image): void {
        const elements = this.elements;
        elements.container.hide();
        elements.previewImage.attr('src', image.content);
        elements.contentInput.val(image.content);
        elements.container.show(350);
    }

    observe(onDelete: (imagePreview: ImagePreview) => void = null) {
        const elements = this.elements;
        const self = this;
        // Delete preview (uploaded image).
        elements.cancelButton.on('click', function() {
            elements.container.hide(350);
            window.setTimeout(() => {
                elements.container.remove();
                onDelete(self);
            }, 350);
        });
    }
}

export default function createImageUploader(component: JQuery, config: IConfig = {}): ImageUploader {
    return new ImageUploader(component, config);
}
