import * as jquery from "jquery";
import generateUri from "../utils/generateUri";

export declare interface IConfig {
    routePath: string;
    filters: {};
}

export class Table {
    constructor(protected tableElement: JQuery, protected config: IConfig) {}

    public observe(): void {
        // Get table filters.
        const filters = this.config.filters;

        // Get table route path.
        const routePath = this.config.routePath;

        // Observe header cell clicks.
        this.tableElement.find("th.tg-th").on("click", (event) => {
            const uri = jquery(event.target).attr("uri");
            if (uri) {
                window.location.replace(uri);
            }
        });

        // Observe row mouse over and mouse out.
        const tableRows = this.tableElement.find("tbody .tg-tr");
        tableRows.hover(
            function() {
                jquery(this).addClass('tg-over');
            },
            function() {
                jquery(this).removeClass('tg-over');
            }
        );
        tableRows.on('click', function() {
            const tr = jquery(this);
            if (tr.hasClass('tg-marked')) {
                tr.removeClass('tg-marked');
            } else {
                tr.addClass('tg-marked');
            }
        });

        // Observe jump to page button.
        const pageInput = this.tableElement.find("input.tg-page-input");
        if (pageInput.length > 0) {
            this.tableElement.find("a.tg-page-btn").on("click", () => {
                const pageNumber = pageInput.val().toString().trim();
                if (/^[0-9]+$/.test(pageNumber)) {
                    window.location.replace(generateUri(routePath, filters, {_p: pageNumber}));
                }
            });
        }
    }
}

export default function createTable(tableElement: JQuery, config: IConfig): Table {
    return new Table(tableElement, config);
}
