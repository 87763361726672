import * as jquery from "jquery";
import createImageUploader, {ImageUploader} from './image_uploader';

export class Form {
    protected _imageUploaders: ImageUploader[] = [];

    constructor(protected _form: JQuery, protected config: {} = {}) {}

    public observe(): void {
        // Initialize image uploaders.
        this._form.find('.tg-form-image-uploader-component').each((index, element) => {
            const imageUploaderElement = jquery(element);
            const params = JSON.parse(imageUploaderElement.find('.params').text());
            const imageUploader = createImageUploader(imageUploaderElement, params);
            imageUploader.observe();
            this._imageUploaders.push(imageUploader);
        });

        // Observe form reset
        this._form.on('reset', (event) => {
            // Ensure the image uploaders are reset after the form is reset.
            // Otherwise, the image uploaders' inputs could be reset to empty when the form is reset.
            window.setTimeout(() => {
                for (const imageUploader of this._imageUploaders) {
                    imageUploader.reset();
                }
            }, 10);
        });
    }
}

export default function createForm(formElement: JQuery, config: {} = {}): Form {
    return new Form(formElement, config);
}
